// extracted by mini-css-extract-plugin
export var title = "recipe-module--title--eK4sv";
export var greySection = "recipe-module--greySection--8wjHX";
export var ingredientsSection = "recipe-module--ingredientsSection--nVjGT";
export var listItem = "recipe-module--listItem--bJLcD";
export var imageWrapperOut = "recipe-module--imageWrapperOut--+W6Dj";
export var imageWrapperIn = "recipe-module--imageWrapperIn--nKoG7";
export var recipeImage = "recipe-module--recipeImage--WA9-k";
export var stepsWrapper = "recipe-module--stepsWrapper--YPPdS";
export var steps = "recipe-module--steps--nTJPe";
export var stepWithImage = "recipe-module--stepWithImage--fFX1x";
export var stepWithoutImage = "recipe-module--stepWithoutImage--0lG-8";